// @ts-strict-ignore
// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { EntityService } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { Industry } from './industry.model';

@Injectable({
  providedIn: 'root',
})
export class IndustryService extends EntityService<Industry> {
  private all$: Observable<Industry[]>;

  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAll$(params?: HttpParams | { [param: string]: string | string[] }): Observable<Industry[]> {
    if (!this.all$) {
      this.all$ = super.getAll$(params).pipe(shareReplay<Industry[]>(1));
    }

    return this.all$;
  }

  getByName$(name: string): Observable<Industry> {
    const req$ = super.getAll$({ name }).pipe(
      map((industries: Industry[]) => {
        if (industries.length === 0) {
          throw new Error(`Industry with name(${name}) not found.`);
        } else if (industries.length > 1) {
          throw new Error(`Multiple industries with name(${name}) found.`);
        }
        return industries[0];
      }),
    );

    return this.handleGetOrCreateResponse(req$, {
      log: `Get ${this.singularIdentifier()} by name(${name}) failed.`,
      nice: `Failed to get ${this.singularIdentifier()}.`,
    });
  }

  pluralIdentifier(): string {
    return 'industries';
  }

  singularIdentifier(): string {
    return 'industry';
  }
}
